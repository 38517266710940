<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-' + item.ID">
                <td>{{ item.VIPName }}</td>
                <td class="text-end">{{ item.BuyDiscount}}%</td>
                <td class="text-end">{{ item.WeightDiscount}}%</td>
                <td class="text-end">{{ item.DepositMin }}%</td>
                <td class="text-center">
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">fa-edit</v-icon>
                  <v-icon medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import { changeSort } from "@/commons/utils";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      search: "",
      headers: [
        {
          text: "Level name",
          value: "VIPName",
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "CK phí mua hàng",
          value: "BuyDiscount",
          align: 'end',
          filterable: true,
          decimal: 0,
          dataType: dataType["Number"]
        },
        {
          text: "CK phí cân nặng",
          value: "WeightDiscount",
          align: 'end',
          filterable: true,
          decimal: 0,
          dataType: dataType["Number"]
        },
        {
          text: "Đặt cọc tối thiểu",
          value: "DepositMin",
          align: 'end',
          filterable: true,
          decimal: 0,
          dataType: dataType["Number"]
        },
        {
          text: "Thao tác",
          align: "center",
          value: "",
          sortable: false,
          filterable: false
        }
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "customer_level",
      filterConditions: [],
      quickFilterConditions: []
    };
  },
  watch: {
    pagination: function(val) {
      let filterConditions = this.$store.state.commons.filterConditions;
      this.filterConditions = !_.isEmpty(filterConditions)
        ? filterConditions[this.viewName]
        : [];
      this.filter_data();
    }
  },
  computed: mapState({
    items: state => state.customerLevel.all.data,
    total_rows: state => state.customerLevel.all.total,
    loading: state => state.customerLevel.loading
  }),
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.$store.dispatch("customerLevel/getCustomerLevelList", param);
    },
    gotoDetail: function(id) {
      this.$router.push({
        name: "CustomerLevelDetail",
        params: { Pid: id, title: `Cấp độ khách hàng (#${id})` }
      });
    },
    deleteItem: function(id) {
      window.getApp.$emit('REQUEST_DELETE_DATA', id);
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData() {
      this.$router.push({
        name: "CustomerLevelDetail",
        params: {
          Pid: 'tao-moi',
          title: `Tạo mới level`
        }
      });
    }
  },
  mounted() {
    this.pagination.sortBy = ["id"];
    this.$root.$emit('REQUEST_ROOT_OPTION', {});
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("CONFIRMED_REMOVE_DATA", (id) => {
      this.$store.dispatch("customerLevel/removeCustomerLevelDetail", id);
      setTimeout(this.filter_data, 200);
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  }
};
</script>